<template>
  <div class="main-card mb-3 card pa-0 ma-0">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{$t('settings.lang_assignReceiptTemplate')}}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
        </div>
      </div>
      <v-card-text class="pa-0 ma-0">
        <v-data-table :headers="headers" :items="cashiers" :loading="loadingCashiers" class="rounded-t-0">
          <template v-slot:item.identifier="{item}">
            <strong class="primary--text">{{ $t('generic.lang_kasse') }} {{ item.identifier }}</strong>
          </template>

          <template v-slot:item.retail_template="{item}">
            <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" v-model="item.retail_template" :options="templates"
                           text-field="name" value-field="id"
                           @change="save($event, item.identifier, 2)">

            </b-form-select>
          </template>

          <template v-slot:item.gastro_template="{item}">
            <b-form-select :class="[$vuetify.theme.dark? 'dark-bg' : '']" v-model="item.gastro_template" :options="templates"
                           text-field="name" value-field="id"
                           @change="save($event, item.identifier, 1)">

            </b-form-select>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
  </div>
</template>

<script>

import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "AssignTemplateComponent",
  components: {},
  data: () => {
    return {
      cashiers: [],
      templates: [],
      loadingCashiers: false,
      loading: false
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_kasse'),
          value: "identifier"
        },
        {
          text: this.$t('generic.lang_gastro'),
          value: "gastro_template",
          sortable: false
        },
        {
          text: this.$t('generic.moduleRetailName'),
          value: "retail_template",
          sortable: false
        }
      ]
    }
  },
  methods: {
    save(template_id, cashier_id, system_type) {
      //console.log(template_id, cashier_id, system_type);
      this.loading = true;
      this.axios.post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.ASSIGN, {
        cashierID: cashier_id,
        templateID: template_id,
        type: system_type
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });
        } else if (!res.data.success) {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => (this.loading = false))
    },
    loadCashiers() {
      this.loadingCashiers = true;
      let promises = [];
      promises.push(this.axios.post(ENDPOINTS.PRINTSETTINGS.TEMPLATES.ASSIGNED));
      promises.push(this.axios.post(ENDPOINTS.DATATABLES.PRINTSETTINGS.TEMPLATES));
      Promise.all(promises).then(this.axios.spread((...res) => {
        if (res[0].data.success) {
          this.cashiers = res[0].data.cashiers;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }


        this.templates = res[1].data.aaData.map((elt) => {
          return {
            id: elt[0],
            name: elt[1]
          }
        });

      })).catch((err) => {

        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => (this.loadingCashiers = false))
    }
  }
  ,
  mounted() {
    this.loadCashiers();

  }
}
</script>

<style scoped>

</style>
