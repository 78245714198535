<template>
  <div>
    <PageTitle
        :icon=icon
        :heading="this.$t('settings.lang_receiptTmpl')"
        :subheading="this.$t('settings.lang_receiptTmpl')"

    ></PageTitle>
    <div class="app-main__inner">
      <templates-component/>
    </div>

  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import TemplatesComponent from "@/components/settings/printerSettings/printManager/templates/index";


export default {
  components: {
    TemplatesComponent,

    PageTitle
  },

  data() {
    return {
      icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    }
  }
}
</script>